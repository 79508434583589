import { Button, ButtonGroup, ButtonToolbar } from 'rsuite';



const MainSection: React.FC<{reference?:any, onClick?:any}> = ({reference = null, onClick}) => {
  return (
    <section ref={reference} className="main-section">
      <div className="section-wrapper bg-[url('https://mechanicpro.foxthemes.me/wp-content/uploads/2016/12/photo-68-1.png')] h-screen bg-no-repeat bg-cover">
        <div className="container mx-auto px-4 h-full">
          <div className="grid lg:grid-cols-12 grid-cols-1 lg:pb-24 content-center lg:content-end h-full text-white">
            <div className="col-span-7 col-start-6">
              <div className="row lg:mb-8">
                <h3 className="title  lg:text-[55px] text-center leading-none mb-5 capitalize">
                  We Can find Your <b className="text-[--rs-orange-500]">auto parts</b> and <b className="text-[--rs-orange-500]">Components</b>.
                </h3>
                <p className="subtitle text-[18px] leading-normal text-center font-normal" style={{ color: "rgba(254,254,254,0.85)!important" }}>
                  Discover top-quality auto parts and components for all your needs. Whether you're looking for spare parts, accessories, tyres, 
                  batteries, lubricants, or heavy machinery, we've got you covered. 
                  Get in touch with us for cooperation and ensure your vehicle stays on the road longer.
                </p>
              </div>
              <div className="flex justify-center mt-5 lg:mt-0 mb-5">
                <Button onClick={() => onClick('contact')} className="!rounded-none w-1/3" appearance="primary" color="orange">Get in touch</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainSection