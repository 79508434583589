import Footer from "../../common/footer/footer.common"
import ContactSection from "./sections/contact.section"
import MainSection from "./sections/main.section"
import MapSection from "./sections/map.section"
import ServiceSection from "./sections/service.section"
import SolvingSection from "./sections/solving.section"



const HomePage:React.FC<{sectionRefs?:any, onClick?:any}> = ({sectionRefs=null, onClick}) => {
  return(
    <div className="home-page">
      <div className="wrapper">
        <MainSection onClick={(e:any) => onClick(e)} reference={sectionRefs?.home} />
        <ServiceSection reference={sectionRefs?.service} />
        <SolvingSection reference={sectionRefs?.about} />
        <ContactSection  reference={sectionRefs?.contact}/>
        <MapSection reference={sectionRefs?.map} />
      </div>
    </div>
  )
}

export default HomePage