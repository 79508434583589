import HomePage from "./pages/home/home.page";
import "./assets/scss/app.scss"
import 'rsuite/dist/rsuite.min.css';
import NavBar from "./common/header/web/navbar.common";
import React from "react";
import Footer from "./common/footer/footer.common";
const App:React.FC = () => {

  const sectionRefs: any = {
    home: React.useRef(null),
    service: React.useRef(null),
    contact: React.useRef(null),
    about: React.useRef(null),
    map: React.useRef(null),
  };

  const scrollToSection = (section: any) => {
    sectionRefs[section]?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <NavBar onClick={scrollToSection} />
      <HomePage sectionRefs={sectionRefs} onClick={scrollToSection} />
      <Footer onClick={scrollToSection} />
    </div>
  );
}

export default App;
