

const NavBar: React.FC<{onClick?:any}> = ({ onClick }) => {
  return (
    <div className="navbar bg-transparent fixed w-full z-[1000]">
      <div className="navbar-wrapper relative">
        <span className="absolute left-0 top-0 right-0 bottom-0 bg-black -z-1 opacity-75"></span>
        <div className="container mx-auto px-4 py-4 relative">
          <div className="flex justify-center lg:justify-between items-center">
            <div className="hidden md:block logo">
              <img className="h-11" src={require('./../../../assets/img/logo.png')} alt="logo-rakz" />
            </div>
            <ul className="flex m-0 p-0 lg:text-lg text-white gap-5 justify-around md:justify-normal font-medium">
              <li onClick={() => onClick('home')} className="m-0 p-0 cursor-pointer">Home</li>
              <li onClick={() => onClick('service')} className="m-0 p-0 cursor-pointer">Service</li>
              <li onClick={() => onClick('about')} className="m-0 p-0 cursor-pointer">About</li>
              <li onClick={() => onClick('contact')} className="m-0 p-0 cursor-pointer">Contact</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar