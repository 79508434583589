


const SolvingSection: React.FC<{reference?:any, onClick?:any}> = ({reference = null, onClick}) => {
  return (
    <div ref={reference} className="solving-section">
      <div className="section-wrapper">
        <div className="h-full bg-[#1c1d18] text-white">
          <div className="grid lg:grid-cols-2 gap-4">
            <div className="column py-20 px-10 md:px-0 md:w-[85%] lg:w-[70%] ml-auto">
              <div className="column-inner flex flex-col gap-10">
                <h3 className="title text-4xl">About Our Company</h3>
                <p className="text-md">
                  We are a leading provider of premium auto accessories, specializing in enhancing vehicle functionality and aesthetics.
                </p>
                <div className="img">
                  <img src={require('./../../../assets/img/logo.png')} alt="logo-rakz" />
                </div>
                <p>
                  Our extensive range includes interior comforts, exterior enhancements, safety features, and essential tools, all designed to meet the diverse needs of our valued customers.
                  With a commitment to quality and customer satisfaction, we strive to exceed expectations in every aspect of automotive accessory trading.
                </p>
              </div>
            </div>
            <div className="column h-full">
              <div className="column-inner h-full">
                <img className="object-cover h-full w-full" src="https://mechanicpro.foxthemes.me/wp-content/uploads/2016/12/photo-69.jpg?id=2087" alt="image-cover" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SolvingSection