import { MdMiscellaneousServices } from "react-icons/md";
import { IoCarSportOutline } from "react-icons/io5";


const ServiceSection: React.FC<{reference?:any, onClick?:any}> = ({reference = null, onClick}) => {


  const TradingCard: React.FC<{title?:string, description?:string, icon?:any}> = ({title, description, icon}) => {
    return (
      <div className="bg-gray-100 border-gray-200 border-[2px] card">
        <div className="card-wrapper">
          <div className="p-5 flex flex-col items-center justify-center text-center gap-5">
            {icon}
            <h3 className="title text-xl">{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    )
  }


  return (
    <section ref={reference} className="service-section">
      <div className="section-wrapper">
        <div className="container mx-auto px-4 h-full">
          <div className="column-inner my-28">
            <div className="row my-16">
              <div className="row-inner flex items-center justify-center gap-5 flex-col">
                <h3 className="title uppercase text-4xl">Our Services</h3>
                <p className="description lg:w-1/3 mx-auto">We specialize in automotive spare parts and components, delivering quality solutions tailored to your needs.</p>
                <MdMiscellaneousServices className="text-6xl text-[--rs-orange-500]" />
              </div>
            </div>
            <div className="row">
              <div className="row-inner flex flex-col gap-5">
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-5">
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Auto Spare Parts & Components Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Auto Accessories Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Tyres & Accessories Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Used Auto Spare Parts Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                </div>
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-5">
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Motor Vehicles Batteries Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Motorcycles Spare Parts & Accessories Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Dry Batteries Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Lubricants & Grease Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                </div>
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-5">
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Equipment & Spare Parts Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Construction Equipment & Machinery Spare Parts Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Heavy Equipment & Machinery Spare Parts Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Engines & Machinery Spare Parts Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                </div>
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-5">
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Used Building Machinery & Equipment Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Used Heavy Equipment & Machinery Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Oilfield & Equipment Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                  <TradingCard 
                    icon={<IoCarSportOutline className="text-5xl text-[--rs-orange-500]" />}
                    title="Natural Gas & Equipment Trading" 
                    description="Our Auto Accessories Trading service offers a diverse selection of high-quality accessories to enhance vehicle performance, comfort, and style" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceSection;